import React, { useState } from 'react';
// import './Todo.css';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, ImageIcon, Button, Modal, Input } from '@material-ui/core'
import db from './firebase';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        // position: 'absolute',
        // width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        alignItems: 'center',
        justifyContent: 'center',
        width: '900',
        maxWidth: '80vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '40%',
        transform: 'translate(0, -50%)',
        overflowY: 'auto'
    },
}))

function Todo(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState('');

    const handleOpen = () => {
        setOpen(true);
    }

    const updateTodo = () => {
        db.collection('todos').doc(props.todoprop.id).set({
            todo: input
        }, { merge: true });
        setInput('');
        setOpen(false);
    }
    return (
        <>
            <Modal open={open} onClose={e => setOpen(false)}>
                <div className={classes.paper}>
                    <h1>
                        Edit {props.todoprop.todo}
                    </h1>
                    <Input type="text" placeholder={'🖊' + props.todoprop.todo} value={input} onChange={event => setInput(event.target.value)} />
                    <hr />
                    <Button onClick={event => updateTodo()} variant="contained" color="primary">
                        Update
                    </Button>
                </div>
            </Modal>
            <List className='todo__list'>
                <ListItem>
                    <ListItemAvatar>
                    </ListItemAvatar>
                    <ListItemText primary={props.todoprop.todo} secondary={props.todoprop.timestamp}>
                    </ListItemText>
                </ListItem>
                <EditIcon onClick={e => setOpen(true)}></EditIcon>
                <DeleteForeverIcon onClick={event => db.collection('todos').doc(props.todoprop.id).delete()} >Delete Me</DeleteForeverIcon>
            </List >
        </>
    )
}

export default Todo